.body {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(-90deg,#03bd50cb,#ffffff);
}
.modalHeader{
  background-color: #50ac67;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.centrado{
  text-align: center;
}
.numeroCeldaCh{
  width: 50px;
  text-align: right;
}
.numero{
  text-align: right;
}
.total{
  text-align: right;
  font-size:16px;
}
.rojo{
 color:red;
 font-weight: bold; 
}
.cancelada{
  color:red;
 }
 .negro{
  color:black;
  font-weight: bold; 
 }
 .opcion{
  color:black;
}
.form-control-sm{
  height:calc(1.2em + .5rem + 2px);
}
.btn-sm{
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.2;
  border-radius:.2rem
}
.input-group-text{
  height:calc(1.2em + .5rem + 0px);
}
.opcionActual{
  text-align: center;
  font-size: medium;
  font-weight: bold;
  color:green;
}
ul {
  list-style:none;
  padding-left: 10px;
}
.opcionMenu::before {content: "• "; color: darkgreen}
li:hover {
  background-color:#2EA620;
  color:white;
}
.thFijo {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #40c931;
}

.wrapperX {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.containerX {
  overflow-y: auto;
  border-top: 41px solid transparent;
  border-bottom: 41px solid transparent;
}
thead tr th div,
tfoot tr td div {
  position: absolute;
  height: 30px;
  padding: 10px;
  color:white;
  background-color:green;
  margin-left: -10px;
  line-height: normal;
  width: 100%;
  z-index: 2;
  text-align:left;
  font-weight: bold;
}

thead tr th div {
  top: 0;
}

tfoot tr td div {
  bottom: 0;
}